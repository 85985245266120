// extracted by mini-css-extract-plugin
export var __wab_expr_html_text = "plasmic_dork_squad-module--__wab_expr_html_text--pAocK";
export var a = "plasmic_dork_squad-module--a--E2-BM";
export var address = "plasmic_dork_squad-module--address--41Aa0";
export var all = "plasmic_dork_squad-module--all--8P3+L";
export var blockquote = "plasmic_dork_squad-module--blockquote--KvhmJ";
export var button = "plasmic_dork_squad-module--button--K4AwH";
export var code = "plasmic_dork_squad-module--code--C3m0P";
export var h1 = "plasmic_dork_squad-module--h1--vb1OS";
export var h2 = "plasmic_dork_squad-module--h2--J+DUE";
export var h3 = "plasmic_dork_squad-module--h3--MVaNu";
export var h4 = "plasmic_dork_squad-module--h4--O39Ob";
export var h5 = "plasmic_dork_squad-module--h5--LOX06";
export var h6 = "plasmic_dork_squad-module--h6--T4jPN";
export var img = "plasmic_dork_squad-module--img--ptlMB";
export var input = "plasmic_dork_squad-module--input--jG0UL";
export var li = "plasmic_dork_squad-module--li--uxnCV";
export var ol = "plasmic_dork_squad-module--ol--QLPjJ";
export var p = "plasmic_dork_squad-module--p--4CHFQ";
export var plasmic_default__a = "plasmic_dork_squad-module--plasmic_default__a--Yvadv";
export var plasmic_default__blockquote = "plasmic_dork_squad-module--plasmic_default__blockquote--k8rd5";
export var plasmic_default__code = "plasmic_dork_squad-module--plasmic_default__code--fLw7s";
export var plasmic_default__component_wrapper = "plasmic_dork_squad-module--plasmic_default__component_wrapper--WOVpT";
export var plasmic_default__h1 = "plasmic_dork_squad-module--plasmic_default__h1--WbBFj";
export var plasmic_default__h2 = "plasmic_dork_squad-module--plasmic_default__h2--h3GQu";
export var plasmic_default__h3 = "plasmic_dork_squad-module--plasmic_default__h3--YdjDv";
export var plasmic_default__h4 = "plasmic_dork_squad-module--plasmic_default__h4--gFgu5";
export var plasmic_default__h5 = "plasmic_dork_squad-module--plasmic_default__h5--R6W21";
export var plasmic_default__h6 = "plasmic_dork_squad-module--plasmic_default__h6--yBWM0";
export var plasmic_default__inline = "plasmic_dork_squad-module--plasmic_default__inline--WQfMn";
export var plasmic_default_styles = "plasmic_dork_squad-module--plasmic_default_styles--1vFdc";
export var plasmic_page_wrapper = "plasmic_dork_squad-module--plasmic_page_wrapper--4Vfq2";
export var plasmic_tokens = "plasmic_dork_squad-module--plasmic_tokens--MIob0";
export var pre = "plasmic_dork_squad-module--pre--gH0Pv";
export var root_reset = "plasmic_dork_squad-module--root_reset--AF74q";
export var select = "plasmic_dork_squad-module--select--3MRy4";
export var span = "plasmic_dork_squad-module--span--rgUeX";
export var textarea = "plasmic_dork_squad-module--textarea--FwanT";
export var ul = "plasmic_dork_squad-module--ul--ub9w9";